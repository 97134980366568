import React from 'react';
import './app-footer.css';

const AppFooter = () => {
  return (
    <footer>
      Если сайт не работает, звоните Владимиру: +7 985 455-24-65
    </footer>
  );
};

export default AppFooter;